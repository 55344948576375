$deco: ccmonstermashworn;

.form-wrapper-contact {
  h2 {
    font-family: $deco;
  }
  form {

    border-radius: 0.5em;
    padding: 1em;
    color: black;
    // border: 2px solid black;
    p {
      font-family: inherit;
    }
    button {
      background: #0a4e61;
      color: white;
      width: 5em;
    }
  }
}
