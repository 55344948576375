$deco: ccmonstermashworn;

.schedule-section {
  padding: 0.5em;
  .title {
    font-family: $deco;
    font-size: 1.5em;
    margin: 1em 0;
    // text-align: center;
  }
}

.today-schedule {
  padding: 0.5em;
  .title, h3 {
    font-family: $deco;
    font-size: 1.5em;
    margin: 1em 0;
    // text-align: center;
  }
}
