@import url("https://use.typekit.net/bca2ulm.css");
$red: #9f0d1c;
$blue: #f70000;
$darkBlue: #720000;
$white: #fffdf9;
// $white: #fff;
$black: #0e0e0e;
$borderWidth: 2px;
$paddingY: 0.7em;
$max-site-width: 100%;
$orange: #ffb802;

$sigFont: franklin-gothic-urw, sans-serif;
$sigFontCompressed: franklin-gothic-urw, sans-serif;
$deco: ccmonstermashworn;
$script: ccmonstermashworn;
$normal: franklin-gothic-urw, sans-serif;

:root {
  // color-scheme: light dark;
}
// $sigFont: expressway, sans-serif;
.ordering-menu {
  // background: $black;
  /* background: none; */
  // color: white;
}

.muiishi.site-content {
  .site-notice-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;
  }
  // background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  background-color: $white;
  // background-color: #f7f7f7;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16'%3E%3Cg fill='%23d6d6d6' fill-opacity='0.24'%3E%3Cpath fill-rule='evenodd' d='M0 .04C2.6.22 4.99 1.1 7 2.5A13.94 13.94 0 0 1 15 0h4c.34 0 .67.01 1 .04v2A12 12 0 0 0 7.17 12h5.12A7 7 0 0 1 20 7.07V14a5 5 0 0 0-3-4.58A5 5 0 0 0 14 14H0V7.07c.86.12 1.67.4 2.4.81.75-1.52 1.76-2.9 2.98-4.05C3.79 2.83 1.96 2.2 0 2.04v-2z'/%3E%3C/g%3E%3C/svg%3E");
  a {
    // color: #770092;
    font-weight: 400;
  }
  .menu-item {
    .photo-container {
      // width: 400px;
    }
  }
}

.muiishi {
  .header-messege {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 1em;
    line-height: 1.5em;
    h2 {
      font-weight: 600;
      font-size: 1.5em;
    }
    a {
      color: $red;
      font-weight: 600;
    }
  }
  .movie-poster {
    font-family: $deco;
    text-align: center;
    p {
      font-size: 1.2em;
    }
    .dot {
      color: $red;
    }
    .title {
      margin-top: 0.3em;
      font-size: 7vw;
      // font-family: ccmonstermash
    }
    .filling-name {
      font-size: 10vw;
      color: $red;
      // line-height: 1.5em;
    }
    .sub-title {
      font-size: 4vw;
    }
  }
}


.muiishi {
  font-family: $sigFont;
  .product-link {
    background: #407841;
    border: none;
    color: $white;
    display: flex;
    align-items: center;
    .plus {
      font-weight: 200;
      position: relative;
      top: -0.052em;
      font-size: 2em;
      line-height: 0.3em;
    }
    &:visited {
      color: $white;
    }
  }
  .carousel-hero {
    // max-height: 400px;
    margin-bottom: 2.5em;
    button {
      box-shadow: none;
      border-radius: none;
      font-size: 0.6em;
    }
  }
  .landing-page-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;

  }
  .info {
    margin: 1em 0;
    text-align: center;
    line-height: 1.4em;
    font-size: 1em;
  }
  .text-section {
    text-align: center;
    line-height: 1.4em;
    .image-header {
      h1 {
        padding: 1em 1em;
        background: black;
        color: white;
        border: 6px solid $red;
        border-top: none;
        border-bottom: none;
      }
      margin-bottom: 2em;
    }
    .header {
      padding: 1em 0;
      font-family: $deco;
      font-weight: 900;
      .title {
        font-size: 1.5em;
      }
    }
    .content {
      font-size: 0.9em;
      max-width: 570px;
      margin: 0 auto;
      padding: 0 0.5em;
      font-family: $sigFont;
    }
  }
  .delivery-note {
    font-family: $sigFontCompressed;
    font-size: 0.6em;
  }
  a.checkout-button.button {
    // background: none !important;
    color: $white;
    background: red;
    // text-transform: capitalize;
    // display: inline-block;
    // border-bottom: 4px solid $red !important;
    // padding: 0em 0.3em !important;
    // font-size: 1em;
    // font-weight: 900 !important;
  }

  svg.nav-bar-logo {
    display: block;
    position: relative;
    height: 35px;
    // margin-top: -0.4em;
    // bottom: -0.6em;
    // padding-top: 0.23em;
    padding-left: 0.3em;
    // padding-bottom: 0.023em;
  }


  .cart.minimized {
    box-sizing: border-box;
    max-width: $max-site-width;
    margin: 0 auto;
    // padding: 0.3em 0;
    padding-left: 0;
    // padding-bottom: 0.1em;
    // background-color: $white;
    background-color: #4bd1d8;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23e2fafd' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
    color: black;
    // background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
    // margin-top: -0.5em;
    // background-color: #00d8ce;
    // background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23010202' fill-opacity='0.29' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
    border: none;
    // border-bottom: 2.5px solid #fcdd2d;

  }


  svg.hero-logo {
    display: block;
    max-height: 300px;
  }

  a.order-online-button {
    // margin: 2em 0;
    font-size: 0.8em;
    @media screen and (max-width: 600px) {
      font-size: 0.6em;
    }
    display: inline-block;
    color: $white;
    text-decoration: none;
    padding: 0.4em 0.8em;
    // margin-right: 1.5em;
    // margin-bottom: 0.5em;
    background: $blue;
    // background: $blue;
    font-family: $deco;
    font-weight: 600;
    box-shadow: none;
    // margin-right: 0.5em;
    // margin: 0.5em;
    // margin-left: 0;
    border-radius: 8px 0 8px 0;
    box-shadow: 1px 1px $darkBlue, 3px 3px $darkBlue, 5px 5px $darkBlue, 7px 7px $darkBlue;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
  }
  .order-bottom-wrapper {
    text-align: center;
    margin: 2em 0;
    a.order-online-button {
      font-size: 4.5vw;
    }
  }
  .sub-truck-title {
    font-family: $deco;
    margin-bottom: 0.5em;
    .bg {
      background: $white;
      padding: 0.2em 0.4em;
      border-radius: 0.3em;
      // display: inline-block;

    }
    // font-color: $white
  }

  .logo-banner {
    padding-left: 0.25em;
    padding-right: 0.25em;
    width: 50%;
    padding: 1em 0;
    display: flex;
    align-items: flex-end;

    justify-content: flex-start;
    background: url(https://afag.imgix.net/muiishi-makirritos-japanese-x-mexican-fusion/hero.jpg?w=1000&auto=format);
    background-size: cover;
    background-position: center;
    min-height: 300px;
  }
  .hero-banner {

    text-align: right;
    background: url(https://afag.imgix.net/muiishi-makirritos-japanese-x-mexican-fusion/tacos-muiishi.jpg?w=1000&auto=format);
    width: 50%;
    padding: 1em 0;
    min-height: 350px;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-shadow: 1px 1px 1px solid white;
    margin-bottom: 0em;

    .icon {
      width: 300px;
      animation-name: spin;
      animation-duration: 500ms;
    }
    @keyframes spin {
      from {
        transform:rotate(0deg) scale(0);
      }
      to {transform:rotate(360deg) scale(1);}
    }


  }
  .hero-wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .text-content {
      margin: 1em;
    }
    @media screen and (max-width: 600px) {
      .logo-banner, .hero-banner {
        width: 100% !important;
      }
    }

  }

  .menu-header {
    font-family: $deco;
    font-size: 2em;
    font-weight: 600;
    margin: 0.5em 0;
    color: $red;

  }

  .location-info {
    padding: 1em 0.5em;
    .text {

      font-size: 2.5vw;
      display: grid;
      grid-gap: 0.4em;
      grid-template-columns: auto auto auto;
      @media screen and (max-width: 680px) {
        font-size: 1em;
        display: block;

        text-align: center;

      }
    }
    p {
      font-size: 0.8em;
    }
    .location {
      grid-column: 1/2;
    }
    .fax, .telephone {
      grid-column: 2/3;
    }
    .hours-section {
      grid-column: 3/4;
    }


    // text-align: center;
    h2 {
      font-family: $deco;
      font-size: 1.3em;
      font-weight: 600;
      margin: 0.5em 0;
      color: $red;
    }
    p {
      font-family: $sigFont;
      margin: 0;
      line-height: 1.5em;
    }
    .hours-section {
      max-width: 280px;
      margin: 0 auto;
    }
    .hours-details {
      display: grid;
      grid-template-columns: auto 1fr;

      .days {
        grid-column: 1/2;
        padding-right: 0.4em;
        text-align: left;
      }
      .hours {
        text-align: left;
        grid-column: 2/3
      }
    }
  }
  .news {
    max-width: 850px;
    margin: 0 auto;
    margin-top: 1.8em;
    padding: 0 0.5em;
    display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    .hero {
      width: 70px;
      margin-right: 0.5em;
      img {
        width: 100%;
        border-radius: 0.3em;
      }
    }
    .full-menu {
      margin-top: 1.5em;

      text-align: right;
      a {
        margin-left: 0.5em;
        background: black;
        color: white;
        padding: 0.2em 0.3em;
        border-radius: 0.2em;
        text-decoration: none;
      }
    }
    h2, h3 {
      font-weight: 900;
      font-size: 1.2em;
      // text-transform: uppercase;
      // margin-bottom: 1em;
      font-family: $deco;
    }
    a {
      // text-transform: uppercase;
      // font-family: $deco;
    }
  }


  .order-benefits-wrapper {

    // background: url(https://afag.imgix.net/hayats-kitchen/hero-1.jpg?w=1200) no-repeat;
    background-size: cover;
    background-position: center 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      color: white;
      background: black;
      padding: 0.2em 0.4em;
    }
  }
  .landing-page .text {
    color: white;
    text-shadow: 1.5px 1.5px 1.5px black;
    padding: 0.5em 0.8em;
    max-width: 360px;
  }

  a.checkout-button.button {
    background: $red;
    font-weight: 600;
    box-shadow: none;
    border: none;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  color: black;

  font-family: $deco;
  font-weight: 600;
  // font-style: italic;
  // text-transform: uppercase;
  // text-decoration: underline;
  font-size: 2.2em;
  // line-height: 0.7em;
  // padding: 0.3em 0.3em 0.2em 0.3em;
  border-radius: 3px;
  background: none;
  position: relative;
}

.menu-category-list-view {
  .menu-item {
    background: none;
    // border-bottom: 1px dashed #bdc0ba;
    border-radius: 0;
    // padding: 0 0.1em;
    box-sizing: border-box;
    font-family: $sigFont;
  }

  // text-transform: lowercase;
  .food-name {
    font-family: $sigFont;
    font-weight: 800;
    font-size: 1.2em;

  }
  .menu-item__description {
    font-family: $sigFont;
  }
  a.product-link {
    .plus {
      font-size: 0.9em;
      font-family: $deco;
    }
  }
  .price {
    font-size: 0.9em;
    font-family: $deco;
    // font-family: $deco;
  }
  .content {
    // font-size: 1.3em;
    // width: 70%;
  }
}


.top-banner {
  min-height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  // text-shadow: 1px 1px 0 $red;
  .text {
    display: inline-block;
    background: black;
    padding: 0.4em 0.5em;
    border-radius: 0.2em;
    // text-transform: uppercase;
    h1 {
      font-weight: 900;
      font-family: $deco;
    }
  }
}

.pages-nav {
  font-size: 0.5em;

  max-width: $max-site-width;
  margin: 0 auto;

  font-family: $sigFont;
  // font-style: italic;
  background: $white;
  // text-transform: uppercase;
  // font-weight: 800;

  display: flex;
  flex-wrap: warp;
  align-items: center;
  .react-toggle {
    margin-right: 0.5em;
  }
  ul {
    padding: 0.5em 0;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: stretch;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}
a.page-link {

  text-decoration: none;
  color: $black;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 0.1em;
  // text-transform: lowercase;
  // font-size: 0.8em;
  line-height: 0.7em;
  // letter-spacing: -0.09em;
  // padding: $paddingY 0.3em;
  padding: $paddingY 0.2em calc(#{$paddingY} - #{$borderWidth}) 0.2em;
  // margin: 0.4em 0.05em;
  // padding: 0.2em 0.2em;
  // box-sizing: border-box;
  // border: $borderWidth solid $white;
  // border-top: none;
  // border-bottom: none;
  border-radius: 0.2em;

  &:hover, &.active {
    color: white;
    background: black;
    // border: $borderWidth solid white;
    // border-top: none;
    // border-bottom: none;

  }
}

a.order-from-old {
  font-size: 0.4em;
  display: inline-block;
  color: white;
  text-decoration: none;
  padding: 0.3em 0.5em;
  background: black;
  font-family: $sigFontCompressed;
  font-weight: 600;
  box-shadow: none;
  // margin-right: 0.5em;
  // margin: 0.5em;
  // margin-left: 0;
  border-radius: 15px;
}

.full-menu-link-wrapper {
  padding: 1em 0;
  text-align: center;
  a.button {
    background: $red;
    color: white;
  }
}
  .menu-item {
    header {
      // font-family: $sigFont;
      // font-size: 1.2em;
    }
  }

  .footer {
    margin-top: 4em;
    background: black;
  }

}

.page-title {

  font-family: $deco;
  font-weight: 600;
  // font-size: 2em;
  padding: 1em 0;
  text-align: center;
}

.photos {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
  .photo-wrapper {

    width: 45%;
    img {
      border: 4px solid black;
      width: 100%;
    }
  }
}


.menu-category-list-view {
  .mag-glass-svg {
    display: none;

  }
}


.page-title {
  text-align: left;
  font-size: 1.5em;
  font-weight: 900;
  font-family: $deco;
  text-transform: uppercase;
  margin-top: 1em;
}
.contact-page {
  .top-banner {
    margin: 1em 0;
    width: 100%;
  }
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 2em;
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5em;
  strong {
    font-weight: 600;
  }
  h2 {
    font-weight: 900;
    font-family: $deco;
    text-transform: uppercase;
    margin: 1em 0;
  }
  ul {
    list-style: disc;
    padding-left: 1em;
  }
  li, p {
    margin: 0.5em 0;
  }

}
